/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-07-20 17:27:12
 * @LastEditTime: 2022-08-23 17:11:03
 */
import axios from '@/utils/request'
let Api = {
	// volunteerPhotoList: "/volunteer/information/portList",
	volunteerPhotoList: "/portraitRecord/list",
	volunteerPhotoVerify: "/portraitRecord/",
	registNumUnbindApply: "/regNumRecord/list",
	registNumUnbindRefuse: "/regNumRecord/pass/",
	cutRecordsApplyList: "/deductionAppeal/list",
	cutRecordsApplyVerify: "/deductionAppeal/",
}
// 志愿者照片审核列表
export function listVolunteerPhoto(params) {
	return axios({
		method: 'get',
		url: Api.volunteerPhotoList,
		params: params
	})
}
// 志愿者照片审核
export function verifyVolunteerPhoto(id, params) {
	return axios({
		method: 'put',
		url: Api.volunteerPhotoVerify + id,
		data: params
	})
}
// 学生注册号解绑申请
export function applyRegistNumUnbind(params) {
	return axios({
		method: 'get',
		url: Api.registNumUnbindApply,
		params: params
	})
}
// 学生注册号解绑申请
export function refuseRegistNumUnbind(id, params) {
	return axios({
		method: 'post',
		url: Api.registNumUnbindRefuse + id,
		data: params
	})
}
// 扣分申诉列表
export function listCutRecordsApply(params) {
	return axios({
		method: 'get',
		url: Api.cutRecordsApplyList,
		params: params
	})
}
// 扣分申诉审核
export function verifyCutRecordsApply(id, params) {
	return axios({
		method: 'put',
		url: Api.cutRecordsApplyVerify + id,
		params: params
	})
}
