<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="unbindApply">
		<el-tabs v-model="currentTab" @tab-click="changeTab">
			<el-tab-pane label="待审核" name="0"></el-tab-pane>
			<el-tab-pane label="已通过" name="1"></el-tab-pane>
			<el-tab-pane label="已拒绝" name="2"></el-tab-pane>
		</el-tabs>
		<div class="unbindApply-content">
			<div class="unbindApply-search">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">学号</span>
						<el-input class="common-row-ipt" placeholder="请输入学号" v-model="searchForm.studentId" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">姓名</span>
						<el-input class="common-row-ipt" placeholder="请输入姓名" v-model="searchForm.name" />
					</div>
					<div class="common-row-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
				</div>
			</div>
			<div class="unbindApply-table">
				<el-table :data="tableData" border>
					<el-table-column prop="regNum" label="新注册号">
					</el-table-column>
					<el-table-column prop="volunteerName" label="姓名">
					</el-table-column>
					<el-table-column prop="gender" label="性别">
						<template slot-scope="scope">
							<span>{{scope.row.gender == true?"男":"女"}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="collegeName" label="学院班级">
						<template slot-scope="scope">
							<span>{{scope.row.collegeName}}{{scope.row.faculty}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="img" label="上传图片">
						<template slot-scope="scope">
							<div class="action-imglist">
								<el-image v-for="(item, index) in scope.row.img" :key="index" fit="cover" class="action-imglist-item" :src="item" :preview-src-list="[item]">
								</el-image>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="状态">
						<template slot-scope="scope">
							<span>{{scope.row.status == 0?"待审核":scope.row.status == 1?"已通过":scope.row.status == 2?"已拒绝":""}}</span>
						</template>
					</el-table-column>
					<el-table-column v-if="currentTab == 0" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('agree', scope)">通过</span>
								<el-divider direction="vertical"></el-divider>
								<span @click="actionFn('refeuse', scope)">拒绝</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<refuse-reason :isOpen="dialogVisible" @cancle="closeDialog" @submit="submitDialog" />
	</div>
</template>

<script>
	import refuseReason from "./components/refuseReason.vue";
	import {
		applyRegistNumUnbind,
		refuseRegistNumUnbind,
	} from "@/api/student";
	export default {
		components: {
			refuseReason
		},
		data() {
			return {
				currentTab: "0",
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				searchForm: {},
				dialogVisible: false,
				unBindData: {},
			};
		},
		mounted() {
			this.getRegistNumUnbindApply();
		},
		methods: {
			changeTab(e) {
				this.currentTab = e.name
				this.pageIndex = 1;
				this.tableData = [];
				this.getRegistNumUnbindApply();
			},
			getRegistNumUnbindApply() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					status: this.currentTab
				}
				if (this.searchForm.studentId) {
					this.$set(params, "studentId", this.searchForm.studentId)
				}
				if (this.searchForm.name) {
					this.$set(params, "name", this.searchForm.name)
				}
				applyRegistNumUnbind(params).then((res) => {
					if (res.code == 0) {
						res.data.forEach(item => {
							if (item.img) {
								item.img = item.img.split(",")
							} else {
								item.img = []
							}
						})
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			actionFn(type, scope) {
				this.unBindData = {
					img: scope.row.img.join(","),
					regNum: scope.row.regNum,
					status: "",
					volunteerId: scope.row.volunteerId,
					createTime: "",
					reason: "",
					id: scope.row.id
				}
				switch (type) {
					case "agree":
						this.$confirm('确定通过此注册号的解绑申请吗?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'error'
						}).then(() => {
							this.unBindData.status = 1
							console.log("actionFn", this.unBindData)
							refuseRegistNumUnbind(scope.row.id, this.unBindData).then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: '解绑申请已通过!'
									});
									this.pageIndex = 1;
									this.tableData = [];
									this.getRegistNumUnbindApply();
								}
							})
						}).catch(() => {});
						break;
					case "refeuse":
						this.unBindData.status = 2
						this.dialogVisible = true
						break;
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getRegistNumUnbindApply();
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.getRegistNumUnbindApply();
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.getRegistNumUnbindApply();
			},
			closeDialog() {
				this.dialogVisible = false
			},
			submitDialog(props) {
				this.$set(this.unBindData, "reson", props)
				console.log("submitDialog", this.unBindData)
				refuseRegistNumUnbind(this.unBindData.id, this.unBindData).then((res) => {
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '解绑申请已拒绝!'
						});
						this.pageIndex = 1;
						this.tableData = [];
						this.getRegistNumUnbindApply();
						this.dialogVisible = false
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}
	
	/deep/.el-button {
		height: 36px;
	}

	.unbindApply {
		.unbindApply-content {
			padding: 0 20px 20px 20px;
			
			.action-imglist {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
			
				.action-imglist-item {
					width: 48px;
					height: 48px;
					margin: 0 4px 4px 0;
				}
			}

			.unbindApply-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.unbindApply-table {
				margin-top: 20px;
			}
		}
	}
</style>
