<template>
	<el-dialog @close="closeDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
		:visible.sync="dialogVisible" width="520px">
		<div slot="title" class="dialog-header">
			<span>{{dialogTitle}}</span>
			<img @click="closeDialog" src="@/assets/images/common/close.png">
		</div>
		<el-form :model="refuseForm" :rules="refuseRules" ref="refuseForm" label-width="100px" class="">
			<el-form-item class="refuseForm-textarea" label="拒绝原因：" prop="reason">
				<el-input type="textarea" v-model="refuseForm.reason" placeholder="请输入"/>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="closeDialog">取 消</el-button>
			<el-button @click="submitDialog" type="primary">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			isOpen: {
				type: Boolean,
				default: function() {
					return false
				}
			},
			refuseId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		watch: {
			isOpen: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs["refuseForm"].clearValidate()
						})
					}
					this.dialogVisible = val
				}
			},
			deep: true
		},
		data() {
			return {
				dialogVisible: false,
				dialogTitle: "拒绝原因",
				refuseForm: {
					reason: ""
				},
				refuseRules: {
					reason: [{
						required: true,
						message: "请输入拒绝原因",
						trigger: "change"
					}],
				},
			};
		},
		methods: {
			closeDialog() {
				this.$emit("cancle")
			},
			submitDialog() {
				this.$refs["refuseForm"].validate(valid => {
					if (valid) {
						this.$emit("submit", this.refuseForm.reason)
					}
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.refuseForm-textarea .el-textarea__inner {
		width: 350px;
		resize: none;
		height: 136px;
		border: 1px solid #DCDFE6;
	}
	
	.refuseForm-textarea {
		margin-top: 20px;
	}
</style>
